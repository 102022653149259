import React from 'react'
import Panel from '../panel'
import _ from 'lodash'
import { useAgency } from '../../hooks/use-agency'
import { FormattedMessage } from 'react-intl'
import { formatMoneyAmount } from '../../utils/helpers'

const fields = [
  {
    path: 'price.yearly_budgeted_building_costs',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.fields.price.yearly_budgeted_building_costs"
          defaultMessage="Yearly budgeted building costs"
        />
        : {formatMoneyAmount(value)}
      </>
    ),
  },
  {
    path: 'price.property_tax',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.fields.price.property_tax"
          defaultMessage="Property tax"
        />
        : {formatMoneyAmount(value)}
      </>
    ),
  },
  {
    path: 'price.recurring_costs',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.fields.price.recurring_costs"
          defaultMessage="Recurring costs"
        />
        : {formatMoneyAmount(value)}
      </>
    ),
  },
  {
    path: 'price.inventory_report_cost',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.fields.price.inventory_report_cost"
          defaultMessage="Inventory report cost"
        />
        : {formatMoneyAmount(value)}
      </>
    ),
  },
  {
    path: 'price.reference_rent',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.fields.price.reference_rent"
          defaultMessage="Reference Rent"
        />
        : {formatMoneyAmount(value)}
      </>
    ),
  },
  {
    path: 'price.base_rent',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.fields.price.base_rent"
          defaultMessage="Base Rent"
        />
        : {formatMoneyAmount(value)}
      </>
    ),
  },
  {
    path: 'price.rent_supplement',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.fields.price.rent_supplement"
          defaultMessage="Rent Supplement"
        />
        : {formatMoneyAmount(value)}
      </>
    ),
  },
  {
    path: 'settings.agency_commission.buyer.fixed_fee',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.settings.agency_commission.buyer.fixed_fee"
          defaultMessage="Buyer fixed fee"
        />
        : {formatMoneyAmount({ amount: value, currency: 'EUR' })}
      </>
    ),
  },
  {
    path: 'settings.agency_commission.buyer.percentage',
    render: (value) => (
      <>
        <FormattedMessage
          id="costs-panel.settings.agency_commission.buyer.percentage"
          defaultMessage="Buyer percentage"
        />
        : {value}%
      </>
    ),
  },
]

export function CostPanel({ property, locale }) {
  const { settings } = useAgency()
  const { negotiation } = property

  if (property.internal_type === 'project') {
    return null
  }
  const { costs, taxes } = property.attributes.price ?? {}

  const fieldsWithData = fields
    .map(({ path, render }) => ({
      render,
      data: _.get(property.attributes, path),
    }))
    .filter(({ data }) => {
      //null or 0
      if (!data) {
        return false
      }
      if (typeof data === 'object') {
        //good: {amount:100, currency: 'EUR'}
        //bad: {amount:100, currency: null}, {amount: null, currency: 'EUR'}, {amount: 0, currency: 'EUR'}, {}, etc
        return !!(data.currency && data.amount)
      }
      //non-zero number
      return true
    })

  if (!costs?.[locale] && !taxes?.[locale] && !fieldsWithData.length) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="costs-and-taxes" />}>
      <div className="o-grid o-grid--gutter o-grid--spaced-vertical">
        {(costs?.[locale] || !!fieldsWithData.length) && (
          <div className="o-grid__item u-1-of-2-bp3">
            <h3 className="panel__subtitle">
              <FormattedMessage id="price-costs" />
            </h3>
            {fieldsWithData.map(({ data, render }) => (
              <p>{render(data)}</p>
            ))}
            {costs[locale] && <p>{costs[locale]}</p>}
          </div>
        )}
        {taxes?.[locale] && (
          <div className="o-grid__item u-1-of-2-bp3">
            <h3 className="panel__subtitle">
              <FormattedMessage id="price-taxes" />
            </h3>
            <p>{taxes[locale]}</p>
          </div>
        )}
      </div>
    </Panel>
  )
}
